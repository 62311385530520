import axios from 'axios';

export function getSTPsForPoint(pointId) {
  return async (dispatch, getState) => {
    let stps;

    if (getState().points[pointId] && getState().points[pointId].point.isMock) {
      stps = getState().points[pointId].stps || [];
    } else {
      const res = await axios.get('/api/stps/getSTPsForPoint/' + pointId);
      stps = res.data;
    }

    dispatch({
      type: 'UPDATE_POINT',
      point: { _id: pointId },
      stps,
    });
  };
}
