import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './css/FloatingInput.scss';

class FloatingInput extends Component {
  static propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    tabIndex: PropTypes.number,
    name: PropTypes.string,
    autoFocus: PropTypes.bool,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    onPaste: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    hint: PropTypes.string,
    error: PropTypes.string,
    inputRef: PropTypes.object,
  };

  static defaultProps = {
    autoFocus: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      });
    }

    if (this.props.autoFocus !== prevProps.autoFocus && this.props.autoFocus) {
      this.inputRef.current.focus();
    }
  }

  render() {
    const { props } = this;

    return (
      <div
        className={`floating-input ${props.className || ''} ${props.error ? 'errorInput' : ''
          }`}
      >
        <input
          type={props.type || 'text'}
          id={props.id}
          value={props.value}
          tabIndex={props.tabIndex}
          name={props.name}
          autoFocus={props.autoFocus}
          onKeyDown={props.onKeyDown}
          onChange={this.onChange}
          onPaste={props.onPaste}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          disabled={props.disabled}
          maxLength={props.maxLength}
          min={props.min}
          max={props.max}
          autoComplete="off"
          required
          ref={props.inputRef || this.inputRef}
          placeholder={props.placeholder}
          onClick={props.onClick}
        />
        <span className="bar" />
        <label>{props.labelName}</label>
        {props.error && <span className="error">{props.error}</span>}
        {props.hint && !props.error && <span className="hint">{props.hint}</span>}
        {props.children}
      </div>
    );
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
    if (this.props.onChange) this.props.onChange(e);
  };
}

export default FloatingInput;
