import React, { useEffect, useReducer, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Popover } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import classnames from 'classnames';
import { uniqBy } from 'lodash';

import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import CollectionTree from 'components/elements/organisms/CollectionTree/CollectionTree';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import PointContainer from 'components/cards/Point/PointContainer';
import Loading from 'components/elements/atoms/loading';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip.js';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import AddPoint from '../AddPoint/AddPoint';
import SourceLogo from 'routes/Portfolio/Component/ActivityTab/TopicsTab/SourceLogo';

import './ManageWritingPromptPoints.scss';

function ManageWritingPromptPoints({
  writingPrompt = {},
  onClose,
  actions,
  points,
  user,
  openConfirmationModal,
  courses,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_SELECTED_POINT_ID':
          return {
            ...state,
            selectedPointId: action.payload,
          };
        case 'SET_EXPANDED_COLLECTION_IDS':
          return {
            ...state,
            expandedCollectionIds: action.payload,
          };
        case 'SET_POINTS':
          return {
            ...state,
            points: action.payload,
          };
        case 'SET_EXPANDED':
          return {
            ...state,
            expanded: action.payload,
          };
        case 'SET_SHOW_ADD_POINT':
          return {
            ...state,
            showAddPoint: action.payload,
          };
        case 'SET_LEFT_SECTION_HEADER':
          return {
            ...state,
            leftSection: action.payload,
          };
        default:
          return state;
      }
    },
    {
      points: writingPrompt.teacherPoints
        ? [...writingPrompt.teacherPoints]
        : [],
      expanded: [],
      expandedCollectionIds: [],
      leftSection: 'YOUR_RESEARCH',
    },
  );

  useEffect(() => {
    actions.getPortfolio(user.user.username).then(() => {
      actions.getPortfolioCollectionPoints(user.user.username);
    });
  }, []);

  useEffect(() => {
    state.points.forEach((pointId) => {
      if (points[pointId]) {
        const point = points[pointId].point;

        if (point.isCollection) {
          if (
            point.subPoints.filter(({ pointId }) => points[pointId]).length !==
            point.subPoints.length
          ) {
            actions.getCollection(point._id);
          }
        }
      } else {
        actions.getPoint(pointId);
      }
    });
  }, [state.points]);

  function onAddPoint(pointId) {
    const index = state.points.indexOf(pointId);

    if (index !== -1) {
      return;
    }

    if (points[pointId] && points[pointId].point.status === 'private') {
      const message = (
        <div className="confirmHeader">
          <div className="description">
            Points in a Writing Prompt must be public. Do you want to make this
            Point public?
          </div>
        </div>
      );

      openConfirmationModal('Confirm Submit', message, async (success) => {
        if (success) {
          await actions.publishPoint(pointId);
          dispatch({
            type: 'SET_POINTS',
            payload: [...state.points, pointId],
          });
        }
      });
    } else {
      dispatch({
        type: 'SET_POINTS',
        payload: [...state.points, pointId],
      });
    }
  }

  function onAdd() {
    if (!state.selectedPointId) {
      return;
    }

    onAddPoint(state.selectedPointId);
  }

  function onRemove(pointId) {
    const index = state.points.indexOf(pointId);
    dispatch({
      type: 'SET_POINTS',
      payload: [
        ...state.points.slice(0, index),
        ...state.points.slice(index + 1),
      ],
    });
  }

  async function onDone() {
    await actions.updatePointsInWritingPrompt(writingPrompt._id, state.points);
    onClose();
  }

  function onToggle(pointId) {
    const index = state.expanded.indexOf(pointId);

    if (index === -1) {
      return dispatch({
        type: 'SET_EXPANDED',
        payload: [...state.expanded, pointId],
      });
    } else {
      return dispatch({
        type: 'SET_EXPANDED',
        payload: [
          ...state.expanded.slice(0, index),
          ...state.expanded.slice(index + 1),
        ],
      });
    }
  }

  function renderCollectionTree() {
    if (state.leftSection !== 'YOUR_RESEARCH') {
      return null;
    }

    return (
      <div>
        <CollectionTree
          selectCollection={(collectionId) => {
            dispatch({ type: 'SET_SELECTED_POINT_ID', payload: collectionId });
          }}
          selectedIds={state.selectedPointId ? [state.selectedPointId] : []}
          multiple={false}
          mode="ALL"
          searchPlaceholder="Search Points"
          showPointPreview={true}
          showSubPointCount={true}
          onToggleExpand={(expandedCollectionIds) => {
            dispatch({
              type: 'SET_EXPANDED_COLLECTION_IDS',
              payload: expandedCollectionIds,
            });
          }}
          expandedCollectionIds={state.expandedCollectionIds}
        />
        <div className="text-center mt-2">
          <RectangularButton onClick={onAdd}>Add</RectangularButton>
        </div>
      </div>
    );
  }

  function renderSourceLogo(url) {
    return <SourceLogo url={url} />;
  }

  function renderReading(reading) {
    const className = classnames(
      'reading cursor-pointer d-flex align-items-center',
      {
        selected: state.selectedPointId === reading.pointId,
      },
    );

    return (
      <div
        className={className}
        key={reading.pointId}
        onClick={() =>
          dispatch({ type: 'SET_SELECTED_POINT_ID', payload: reading.pointId })
        }
      >
        {renderSourceLogo(
          reading.readingFormat !== 'url' &&
            reading.sourceURL
            ? reading.sourceURL
            : reading.url,
        )}
        <div className="flex-fill ml-2">{reading.title}</div>
      </div>
    );
  }

  function renderReadings() {
    if (state.leftSection !== 'READINGS') {
      return null;
    }

    const course = courses.find((i) => i.courseId === writingPrompt.courseId);

    if (!course) {
      return null;
    }

    const topic = course.topics.find((i) => i._id === writingPrompt.topicId);

    if (!topic) {
      return null;
    }

    const readings = uniqBy(
      topic.readings.filter((i) => i.pointId),
      'pointId',
    );
    return (
      <Fragment>
        <div className="readings">{readings.map(renderReading)}</div>
        <div className="text-center mt-2">
          <RectangularButton onClick={onAdd}>Add</RectangularButton>
        </div>
      </Fragment>
    );
  }

  function renderCollection(pointId) {
    const pointObject = points[pointId];

    if (!pointObject) {
      return (
        <div className="selected-point" key={pointId}>
          <Loading spinning />
        </div>
      );
    }

    const point = pointObject.point;

    const isExpanded = state.expanded.indexOf(pointId) !== -1;
    let subPoints;

    if (isExpanded) {
      subPoints = (
        <div className="selected-collection">
          {point.subPoints.map((i) => renderPoint(i.pointId, false))}
        </div>
      );
    }

    return (
      <Fragment>
        <div
          className="d-flex align-items-center selected-point cursor-pointer"
          key={pointId}
        >
          <button
            type="button"
            className="btn btn-link btn-xs pl-0 d-inline-flex align-items-center flex-shrink-0"
            onClick={() => {
              onToggle(pointId);
            }}
            style={{ width: 25 }}
          >
            <Icon
              icon={isExpanded ? ICONS.MINUS : ICONS.ADD}
              className="av-blue mr-0"
            />
          </button>
          <div className="flex-fill text d-flex align-items-center">
            {point.sourceURL ? renderSourceLogo(point.sourceURL) : null}
            {point.text}
          </div>
          {renderRemoveBtn(pointId)}
        </div>
        {subPoints}
      </Fragment>
    );
  }

  function renderRemoveBtn(pointId, showRemove = true) {
    if (!showRemove) {
      return null;
    }

    return (
      <button
        type="button"
        className="btn btn-link btn-xs ml-2 d-inline-flex align-items-center"
        onClick={() => {
          onRemove(pointId);
        }}
      >
        <Icon icon={ICONS.DELETE} className="av-red" />
      </button>
    );
  }

  function renderTextLimitTooltip(text) {
    const MAX_CHARACTER_LIMIT = 150;
    const hasMoreText = text.length > MAX_CHARACTER_LIMIT;

    if (!hasMoreText) {
      return null;
    }

    return (
      <ToolTip
        toolTipText={`Your Point has ${text.length} characters of text. While writing their essay, students will have to expand the Point to view the full text. Consider shortening the Point.`}
        toolTipPosition="left"
        className="d-inline-flex ml-2 av-red"
      >
        <Icon icon={ICONS.INFO} className="m-0" />
      </ToolTip>
    );
  }

  function renderPoint(pointId, showRemove = true) {
    const pointObject = points[pointId];

    if (!pointObject) {
      return (
        <div className="selected-point" key={pointId}>
          <Loading spinning />
        </div>
      );
    }

    const point = pointObject.point;

    if (point.isCollection) {
      return null;
    }

    let text;

    if (point.text) {
      text = point.text;
    } else if (point.sourceText) {
      text = `"${point.sourceText}"`;
    } else if (point.sourceTitle) {
      text = `"${point.sourceTitle}"`;
    } else if (point.sourceName) {
      text = <i>{point.sourceName}</i>;
    }

    return (
      <div
        className="d-flex align-items-center selected-point cursor-pointer"
        key={pointId}
      >
        <div
          className="bg-av-blue flex-shrink-0"
          style={{
            width: 8,
            height: 8,
            borderRadius: '100%',
            marginLeft: 5,
            marginRight: 10,
          }}
        ></div>
        <OverlayTrigger
          trigger="hover"
          placement="left"
          delay={1000}
          key={pointId}
          overlay={
            <Popover placement="right">
              <div>
                <PointContainer
                  point={point}
                  user={user}
                  cardType="card"
                  isSelectionEnabled={false}
                />
              </div>
            </Popover>
          }
        >
          <div className="flex-fill text">{text}</div>
        </OverlayTrigger>
        {renderTextLimitTooltip(text)}
        {renderRemoveBtn(pointId, showRemove)}
      </div>
    );
  }

  function renderSelectedPoint(pointId) {
    const pointObject = points[pointId];

    if (!pointObject) {
      return (
        <div className="selected-point" key={pointId}>
          <Loading spinning />
        </div>
      );
    }

    const point = pointObject.point;

    if (point.isCollection) {
      return renderCollection(pointId);
    } else {
      return renderPoint(pointId);
    }
  }

  function renderSelectedPoints() {
    let points;

    if (state.points.length) {
      points = state.points.map(renderSelectedPoint);
    } else {
      points = (
        <p className="av-gray text-center mt-4">
          <i>No Points</i>
        </p>
      );
    }

    return <div className="selected-points">{points}</div>;
  }

  function renderAddPoint() {
    if (!state.showAddPoint) {
      return null;
    }

    return (
      <Fragment>
        <div className="d-flex align-items-center mb-3 font-size-12">
          <h4 className="m-0 av-blue font-weight-600 text-center">
            Create New Point
          </h4>

          <ActionLink
            onClick={() => {
              dispatch({ type: 'SET_SHOW_ADD_POINT', payload: false });
            }}
            linkClassName="av-red ml-auto p-0"
            iconType="averPointIcon"
            iconName="CLOSE"
            label="Close"
          />
        </div>
        <AddPoint
          model={{
            title: 'Create Point',
            status: 'public',
            showAddToCollection: true,
            showStance: false,
            hideImportFromPortfolio: true,
            afterPointCreated: (
              pointId,
              _dispatch,
              _stance,
              collectionId,
              savedPoint,
            ) => {
              console.log('Saved Point', savedPoint);
              console.log('Saved Collection', collectionId);
              dispatch({ type: 'SET_SHOW_ADD_POINT', payload: false });
              dispatch({ type: 'SET_SELECTED_POINT_ID', payload: pointId });

              const index = state.expandedCollectionIds.indexOf(collectionId);

              if (index === -1) {
                let expandedCollectionIds = [
                  ...state.expandedCollectionIds,
                  collectionId,
                ];
                dispatch({
                  type: 'SET_EXPANDED_COLLECTION_IDS',
                  payload: expandedCollectionIds,
                });
              }
            },
          }}
        />
      </Fragment>
    );
  }

  function renderYourResearchHeaderItem() {
    const className = classnames(
      'm-0 font-weight-600 text-center cursor-pointer',
      {
        'av-blue': state.leftSection === 'YOUR_RESEARCH',
        'gray-3': state.leftSection !== 'YOUR_RESEARCH',
      },
    );
    return (
      <h4
        className={className}
        onClick={() =>
          dispatch({
            type: 'SET_LEFT_SECTION_HEADER',
            payload: 'YOUR_RESEARCH',
          })
        }
      >
        Your Research
      </h4>
    );
  }

  function renderTopicReadingsHeaderItem() {
    const className = classnames(
      'm-0 ml-4 font-weight-600 text-center cursor-pointer',
      {
        'av-blue': state.leftSection === 'READINGS',
        'gray-3': state.leftSection !== 'READINGS',
      },
    );
    return (
      <h4
        className={className}
        onClick={() =>
          dispatch({ type: 'SET_LEFT_SECTION_HEADER', payload: 'READINGS' })
        }
      >
        Readings
      </h4>
    );
  }

  function renderLeftSectionHeader() {
    return (
      <div className="d-flex align-items-center mb-3 font-size-12">
        {renderYourResearchHeaderItem()}
        {renderTopicReadingsHeaderItem()}
        <ActionLink
          onClick={() => {
            dispatch({ type: 'SET_SHOW_ADD_POINT', payload: true });
          }}
          linkClassName="av-blue ml-auto p-0"
          iconType="averPointIcon"
          iconName="ADD"
          label="Create New Point"
        />
      </div>
    );
  }

  function renderCollectionSelection() {
    if (state.showAddPoint) {
      return null;
    }

    return (
      <Fragment>
        {renderLeftSectionHeader()}
        {renderCollectionTree()}
        {renderReadings()}
      </Fragment>
    );
  }

  function renderLeftSection() {
    return (
      <Fragment>
        {renderCollectionSelection()}
        {renderAddPoint()}
      </Fragment>
    );
  }

  return (
    <Modal
      show={true}
      className="manage-writing-prompt-points-modal"
      bsSize="lg"
    >
      <Modal.Header>
        <Modal.Title>Writing Prompt Points</Modal.Title>
        <button
          type="button"
          className="btn btn-close btn-link"
          onClick={() => {
            onClose();
          }}
        >
          <Icon icon={ICONS.CLOSE} />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-6">{renderLeftSection()}</div>
          <div className="col-sm-6">
            <h4 className="mt-0 mb-3 av-blue font-weight-600 text-center">
              Selected Points
            </h4>
            {renderSelectedPoints()}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <RoundedButton label="Done" onClick={onDone} />
      </Modal.Footer>
    </Modal>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    points: state.points,
    user: state.user,
    courses: state.classRoom.courses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openConfirmationModal: (title, message, onResponse) => {
      const props = {
        model: { title, message },
        onResponse,
      };
      dispatch(Actions.openModal('confirm', props));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageWritingPromptPoints);
