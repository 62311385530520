import axios from 'axios';
// import qs from 'qs';

import { showHint } from 'redux/actions/hint';

import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_ANALYZE_MEDIA_LOGS = 'LOAD_ANALYZE_MEDIA_LOGS';
export const LOAD_LAST_WEEK_ANALYZE_MEDIA_LOGS =
  'LOAD_LAST_WEEK_ANALYZE_MEDIA_LOGS';
export const LOAD_READING_HISTORY = 'LOAD_READING_HISTORY';
export const LOAD_GRAPH_DATA = 'LOAD_GRAPH_DATA';
export const LOAD_MEDIA_CHANNEL = 'LOAD_MEDIA_CHANNEL';
export const LOAD_CURATED_ARTICLES = 'LOAD_CURATED_ARTICLES';
export const UPDATE_CURATED_ARTICLE = 'UPDATE_CURATED_ARTICLE';
export const UPDATE_ARTICLE_OF_DAY = 'UPDATE_ARTICLE_OF_DAY';
export const LOAD_PAST_TALKS = 'LOAD_PAST_TALKS';
export const REMOVE_COMMUNITY_PICK_ARTICLE = 'REMOVE_COMMUNITY_PICK_ARTICLE';
export const RECLASSIFY_COMMUNITY_PICK_ARTICLE =
  'RECLASSIFY_COMMUNITY_PICK_ARTICLE';
export const LOAD_ARTICLES_FOR_CLASSIFICATION =
  'LOAD_ARTICLES_FOR_CLASSIFICATION';
export const REMOVE_ARTICLE_FROM_CLASSIFICATION =
  'REMOVE_ARTICLE_FROM_CLASSIFICATION';
export const UPDATE_NOTE_ARTICLE_FROM_CLASSIFICATION =
  'UPDATE_NOTE_ARTICLE_FROM_CLASSIFICATION';
export const UPDATE_APPROVED_FOR_NEWS_FEED = 'UPDATE_APPROVED_FOR_NEWS_FEED';

// Right now we have only support for Production lambda function
// So for production environment only, add this environment variable
const REACT_APP_LOG_MEDIA_LAMBDA_URL =
  process.env.REACT_APP_LOG_MEDIA_LAMBDA_URL || '';

export function loadAnalyzeMediaLogs(mediaLogs) {
  return {
    type: LOAD_ANALYZE_MEDIA_LOGS,
    mediaLogs,
  };
}

export function loadLastWeekAnalyzeMediaLogs(mediaLogs) {
  return {
    type: LOAD_LAST_WEEK_ANALYZE_MEDIA_LOGS,
    mediaLogs,
  };
}

export function loadReadingHistory(logs) {
  return {
    type: LOAD_READING_HISTORY,
    logs,
  };
}

export function loadGraphData(graphData, timeRange) {
  return {
    type: LOAD_GRAPH_DATA,
    graphData,
    timeRange,
  };
}

export function loadMediaChannel(mediaChannel) {
  return {
    type: LOAD_MEDIA_CHANNEL,
    mediaChannel,
  };
}

export function loadCuratedArticles(articles, range) {
  return {
    type: LOAD_CURATED_ARTICLES,
    articles,
    range,
  };
}

export function updateCuratedArticle(article) {
  return {
    type: UPDATE_CURATED_ARTICLE,
    article,
  };
}

export function updateArticleOfDay(article) {
  return {
    type: UPDATE_ARTICLE_OF_DAY,
    article,
  };
}

export function loadPastTalks(pastTalks) {
  return {
    type: LOAD_PAST_TALKS,
    pastTalks,
  };
}

export function loadArticlesForClassification(articles) {
  return {
    type: LOAD_ARTICLES_FOR_CLASSIFICATION,
    articles,
  };
}

export function removeArticleFromClassification(articleId) {
  return {
    type: REMOVE_ARTICLE_FROM_CLASSIFICATION,
    articleId,
  };
}

export function updateNoteArticleFromClassification(articleId, note) {
  return {
    type: UPDATE_NOTE_ARTICLE_FROM_CLASSIFICATION,
    articleId,
    note,
  };
}

export function updateApprovedForNewsFeed(articleId) {
  return {
    type: UPDATE_APPROVED_FOR_NEWS_FEED,
    articleId,
  };
}

export function removeCommunityPickArticle(pointId) {
  return {
    type: REMOVE_COMMUNITY_PICK_ARTICLE,
    pointId,
  };
}

export function reclassifyCommunityPickArticle(pointId, sourceAnalytics) {
  return {
    type: RECLASSIFY_COMMUNITY_PICK_ARTICLE,
    pointId,
    sourceAnalytics,
  };
}

export function logMedia(url, username, logSource) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: REACT_APP_LOG_MEDIA_LAMBDA_URL || '/api/mediaLogs/logMedia',
        data: {
          username,
          URL: url,
          logSource,
          hasExtension: document.getElementById('averpoint_app') ? true : false,
        },
      })
        .then((response) => {
          console.log('Successfully logged media', response.data);
          const data = response.data;
          if (data.success) {
            toast(data.message);
            resolve();
          } else {
            toast(data.message, { type: 'error' });
            reject();
          }
        })
        .catch((error) => {
          console.log('Error while logging media', error);
          toast('Error occurred while logging media. Please try again.', {
            type: 'error',
          });
          reject(error);
        });
    });
  };
}

export function getMediaLogsOverTime(timeRange, portfolioUsername) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mediaLogs/getMediaLogsOverTime', {
          params: { timeRange, username: portfolioUsername },
        })
        .then((response) => {
          const data = response.data;
          dispatch(loadReadingHistory(data.mediaLogs));
          resolve();
        })
        .catch((error) => {
          console.log('Error while fetching media', error);
          toast('Error occurred while fetching media. Please try again.', {
            type: 'error',
          });
          reject(error);
        });
    });
  };
}

export function getGraphData(timeRange, bypassCache = 'false', username) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mediaLogs/getGraphData', {
          params: { timeRange, bypassCache, username },
        })
        .then((response) => {
          const data = response.data;
          dispatch(loadGraphData(data, timeRange));
          resolve();
        })
        .catch((error) => {
          console.log('Error while fetching graph data', error);
          toast('Error occurred while fetching graph data. Please try again.', {
            type: 'error',
          });
          reject(error);
        });
    });
  };
}

export function analyzeMediaLogsOverTime(startDate, endDate) {
  return (dispatch) => {
    return new Promise((resolve, _) => {
      axios
        .get('/api/mediaLogs/analyzeMediaLogsOverTime', {
          params: { startDate, endDate },
        })
        .then((response) => {
          const data = response.data;
          const analyzedMediaLogs =
            data && data.analyzedMediaLogs ? data.analyzedMediaLogs : [];
          dispatch(loadAnalyzeMediaLogs(analyzedMediaLogs));
          resolve();
        })
        .catch((error) => {
          console.log('Error while fetching media', error);
          toast('Error occurred while fetching media. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function getMediaChannel() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mediaChannel/getMediaChannel')
        .then((response) => {
          const data = response.data;
          dispatch(loadMediaChannel(data));
          resolve();
        })
        .catch((error) => {
          console.log('Error while fetching media', error);
          toast('Error occurred while fetching media. Please try again.', {
            type: 'error',
          });
          reject(error);
        });
    });
  };
}

export function saveCuratedArticle(point) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/mediaChannel/saveCuratedArticle', { point })
        .then((response) => {
          const data = response.data;
          console.log(data);
          resolve();
        })
        .catch((error) => {
          console.error('Error while saving curated article', error);
          toast('Error while saving curated article. Please try again.', {
            type: 'error',
          });
          reject(error);
        });
    });
  };
}

export function getCuratedArticles(range) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mediaChannel/getCuratedArticles', {
          params: { range },
        })
        .then((response) => {
          const data = response.data;
          dispatch(loadCuratedArticles(data, range));
          resolve();
        })
        .catch((error) => {
          console.error('Error while fetching curated articles', error);
          toast('Error while fetching curated articles. Please try again.', {
            type: 'error',
          });
          reject(error);
        });
    });
  };
}

export function setStatusOfCuratedArticles(id, status, range) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mediaChannel/setStatusOfCuratedArticles', {
          params: { id, status },
        })
        .then((response) => {
          dispatch(
            updateCuratedArticle({ _id: id, curatedArticleId: id, status }),
          );
          resolve();
        })
        .catch((error) => {
          console.error(
            'Error while changing status of curated article',
            error,
          );
          toast(
            'Error while changing status of curated article. Please try again.',
            {
              type: 'error',
            },
          );
          reject(error);
        });
    });
  };
}

export function setArticleOfTheDay(id, status) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mediaChannel/setArticleOfTheDay', {
          params: { id, status },
        })
        .then((response) => {
          dispatch(
            updateArticleOfDay({ _id: id, curatedArticleId: id, status }),
          );
          resolve();
        })
        .catch((error) => {
          console.error('Error while selecting article of day', error);
          toast('Error while selecting article of day. Please try again.', {
            type: 'error',
          });
          reject(error);
        });
    });
  };
}

export function quizExpandedEvent(pointId) {
  return () => {
    return new Promise(() => {
      axios
        .get('/api/mediaChannel/quizExpandedEvent', {
          params: { pointId },
        })
        .then((response) => {
          console.log('Event updated', response);
        })
        .catch((error) => {
          console.error('Error while updating event', error);
        });
    });
  };
}

export function setTopStoryCuratedArticles(id, status) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mediaChannel/setTopStoryCuratedArticles', {
          params: { id, status },
        })
        .then((response) => {
          dispatch(
            updateCuratedArticle({
              _id: id,
              curatedArticleId: id,
              isTopStory: status,
            }),
          );
          resolve();
        })
        .catch((error) => {
          console.error(
            'Error while changing status of curated article',
            error,
          );
          toast(
            'Error while changing status of curated article. Please try again.',
            {
              type: 'error',
            },
          );
          reject(error);
        });
    });
  };
}

export function checkQuizAnswer(questionId, answers) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/quiz/answerQuizQuestion', {
          questionId,
          answers,
        })
        .then((response) => {
          const data = response.data;
          resolve(data);
        })
        .catch((error) => {
          console.error('Error while submitting answer', error);
          reject(error);
        });
    });
  };
}

export function getPastTalks(pastTalkId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mediaChannel/pastTalks', {
          params: { midWeekId: pastTalkId },
        })
        .then((response) => {
          const data = response.data;
          dispatch(loadPastTalks(data));
          resolve();
        })
        .catch((error) => {
          console.log('Error while fetching past talks', error);
          toast('Error occurred while fetching past talks. Please try again.', {
            type: 'error',
          });
          reject(error);
        });
    });
  };
}

export function getThisWeekAnnouncement() {
  return (dispatch, getState) => {
    console.log('getThisWeekAnnouncement called ');

    return axios
      .get('/api/mediaChannel/thisWeekAnnouncement')
      .then((response) => {
        console.log('thisWeekAnnouncement responded successfully');
        //console.log(response);
        const data = response.data;
        if (data.hint) {
          dispatch(showHint('info', data.hint, 'MIC', true));
          localStorage.setItem(
            'thisWeekAnnouncementShownAt',
            new Date().getTime().toString(),
          );
        }
      })
      .catch((error) => {
        console.error('Error while getting thisWeekAnnouncement', error);
      });
  };
}

export function getArticlesForClassification() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/sources/getArticlesForClassification')
        .then((response) => {
          const data = response.data;
          dispatch(loadArticlesForClassification(data.points));
          resolve();
        })
        .catch((error) => {
          console.error('Error while fetching articles', error);
          toast('Error while fetching articles. Please try again.', {
            type: 'error',
          });
          reject(error);
        });
    });
  };
}

export function updateArticleClassification(
  articleMediaCollectionId,
  sourceClassify,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/sources/updateArticleClassification', {
          articleMediaCollectionId,
          sourceClassify,
        })
        .then((response) => {
          dispatch(removeArticleFromClassification(articleMediaCollectionId));
          resolve();
        })
        .catch((error) => {
          console.error('Error while updating article classification', error);
          toast(
            'Error while updating article classification. Please try again.',
            {
              type: 'error',
            },
          );
          reject(error);
        });
    });
  };
}

export function sendNoteToDeveloper(point, note) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/sources/sendNoteToDeveloper', {
          point,
          note,
        })
        .then((response) => {
          toast('Successfully sent a note to the developer.');
          dispatch(updateNoteArticleFromClassification(point._id, note));
          resolve();
        })
        .catch((error) => {
          console.error('Error while fetching articles', error);
          toast('Error while sending note to developer. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function markApprovedForNewsFeed(point) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/sources/markApprovedForNewsFeed', {
          pointId: point._id,
        })
        .then((response) => {
          toast('An article is approved for news feed.');
          dispatch(updateApprovedForNewsFeed(point._id));
          resolve();
        })
        .catch((error) => {
          console.error(
            'Error while approving an article for news feed',
            error,
          );
          toast(
            'Error while approving an article for news feed. Please try again.',
            {
              type: 'error',
            },
          );
          resolve();
        });
    });
  };
}

export function getExtensionDebugLogs() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/mediaLogs/getExtensionDebugLogs')
        .then((response) => {
          const data = response.data;
          resolve(data.extensionDebugLogs);
        })
        .catch((error) => {
          console.error('Error while fetching extension debug logs', error);
          toast(
            'Error while fetching extension debug logs. Please try again.',
            {
              type: 'error',
            },
          );
          resolve({});
        });
    });
  };
}

export function analyzeDebugLogsImpact(debugLogs, username) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/debugLogs/analyzeDebugLogsImpact', {
          debugLogs,
          username,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while analyzing debug logs', error);
          toast('Error while analyzing debug logs. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function acceptDebugLogs(debugLogs, username, logSource) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/debugLogs/acceptDebugLogs', {
          debugLogs,
          username,
          logSource,
        })
        .then((response) => {
          toast('Debug logs are accepted');
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while accepting debug logs', error);
          toast('Error while accepting debug logs. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}
