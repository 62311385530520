import React, { useState } from 'react';

import { Icon, ICONS } from 'components/Icons';

import ContactComponent from './ContactComponent';

import './contactButton.scss';

function ContactButton() {
  // Declare a new state variable, which we'll call "count"
  const [isOpen, setIsOpen] = useState(false);

  if (!isOpen) {
    return (
      <div className="contact-button">
        <button
          onClick={e => {
            e.stopPropagation();
            setIsOpen(true);
          }}
          type="button"
        >
          <span className="text">Contact us</span>
          <Icon icon={ICONS.ARROW_UP} />
        </button>
      </div>
    );
  }

  return (
    <div className="contact-pane">
      <ContactComponent
        showClose
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </div>
  );
}

export default ContactButton;
