import axios from 'axios';

import {
  loadAnalyzeMediaLogs,
  loadLastWeekAnalyzeMediaLogs,
} from 'redux/actions/mediaLogs.js';
import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_THIS_WEEK_GOALS = 'LOAD_THIS_WEEK_GOALS';
export const LOAD_THIS_MID_WEEK_GOALS = 'LOAD_THIS_MID_WEEK_GOALS';
export const LOAD_LAST_WEEK_GOALS = 'LOAD_LAST_WEEK_GOALS';
export const LOAD_LAST_MID_WEEK_GOALS = 'LOAD_LAST_MID_WEEK_GOALS';
export const LOAD_DAILY_ACTIVITY = 'LOAD_DAILY_ACTIVITY';
export const LOAD_LAST_WEEK_DAILY_ACTIVITY = 'LOAD_LAST_WEEK_DAILY_ACTIVITY';
export const LOAD_LEVEL_GOALS = 'LOAD_LEVEL_GOALS';
export const LOAD_LAST_WEEK_LEVEL_GOALS = 'LOAD_LAST_WEEK_LEVEL_GOALS';
export const LOAD_GOALS_DEFINITION = 'LOAD_GOALS_DEFINITION';
export const LOAD_NOT_EARNED_BADGES = 'LOAD_NOT_EARNED_BADGES';
export const LOAD_LEVEL_GOALS_HELPER = 'LOAD_LEVEL_GOALS_HELPER';
export const LOAD_EARNED_BADGES = 'LOAD_EARNED_BADGES';

export function loadThisWeekGoals(weeklyGoals) {
  return {
    type: LOAD_THIS_WEEK_GOALS,
    weeklyGoals,
  };
}
export function loadThisMidWeekGoals(midWeeklyGoals) {
  return {
    type: LOAD_THIS_MID_WEEK_GOALS,
    midWeeklyGoals,
  };
}
export function loadLastWeekGoals(weeklyGoals) {
  return {
    type: LOAD_LAST_WEEK_GOALS,
    weeklyGoals,
  };
}
export function loadLastMidWeekGoals(midWeeklyGoals) {
  return {
    type: LOAD_LAST_MID_WEEK_GOALS,
    midWeeklyGoals,
  };
}
export function loadDailyActivity(dailyActivity) {
  return {
    type: LOAD_DAILY_ACTIVITY,
    dailyActivity,
  };
}
export function loadLastWeekDailyActivity(lastWeekDailyActivity) {
  return {
    type: LOAD_LAST_WEEK_DAILY_ACTIVITY,
    lastWeekDailyActivity,
  };
}
export function loadLevelGoalsHelper(levelGoals) {
  return {
    type: LOAD_LEVEL_GOALS_HELPER,
    levelGoals,
  };
}
export function loadGoalsDefinition(goalsDefinition) {
  return {
    type: LOAD_GOALS_DEFINITION,
    goalsDefinition,
  };
}
export function loadNotEarnedBadges(notEarnedBadges) {
  return {
    type: LOAD_NOT_EARNED_BADGES,
    notEarnedBadges,
  };
}
export function loadLevelGoals(levelGoals) {
  return {
    type: LOAD_LEVEL_GOALS,
    levelGoals,
  };
}
export function loadLastWeekLevelGoals(levelGoals) {
  return {
    type: LOAD_LAST_WEEK_LEVEL_GOALS,
    levelGoals,
  };
}
export function loadEarnedBadges(earnedBadges) {
  return {
    type: LOAD_EARNED_BADGES,
    earnedBadges,
  };
}

export function saveWeeklyGoals(dateForWeek, goals) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/weeklyGoals/saveWeeklyGoals',
        data: {
          dateForWeek: dateForWeek,
          goals: goals,
        },
      })
        .then((response) => {
          console.log('Successfully saved weekly goals', response.data);
          const data = response.data;
          toast('Successfully saved goals');
          dispatch(loadThisWeekGoals(data.weeklyGoals));
          resolve();
        })
        .catch((error) => {
          console.log('Error while saving weekly goals', error);
          toast('Error occurred while saving weekly goal. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function saveWeeklyGoalsForCurrentWeek(goals) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/weeklyGoals/saveWeeklyGoalsForCurrentWeek',
        data: {
          goals: goals,
        },
      })
        .then((response) => {
          console.log(
            'Successfully saved weekly goals for current week',
            response.data,
          );
          const data = response.data;
          toast('Successfully saved goals');
          dispatch(loadThisWeekGoals(data.weeklyGoals));
          resolve();
        })
        .catch((error) => {
          console.log('Error while saving weekly goals', error);
          toast('Error occurred while saving weekly goal. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function getWeeklyGoalsOverTime(startDate, endDate, isLastWeek = false) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/weeklyGoals/getWeeklyGoalsOverTime', {
          params: { startDate, endDate },
        })
        .then((response) => {
          const data = response.data;
          let weeklyGoals = [];
          if (
            data &&
            Array.isArray(data.weeklyGoals) &&
            data.weeklyGoals.length &&
            data.weeklyGoals[0] &&
            Array.isArray(data.weeklyGoals[0].goals)
          ) {
            weeklyGoals = data.weeklyGoals[0].goals;
          }
          if (isLastWeek) {
            dispatch(loadLastWeekGoals(weeklyGoals));
          } else {
            dispatch(loadThisWeekGoals(weeklyGoals));
          }
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching weekly goals', error);
          toast(
            'Error occurred while fetching weekly goals. Please try again.',
            { type: 'error' },
          );
          resolve();
        });
    });
  };
}

export function sendWeeklySummary(startDate, endDate) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/weeklyGoals/sendWeeklySummary', {
          params: { startDate, endDate },
        })
        .then(() => {
          toast('Weekly summary sent successfully');
        })
        .catch((error) => {
          console.log('Error while sending weekly summary', error);
          toast(
            'Error occurred while sending weekly summary. Please try again.',
            { type: 'error' },
          );
          reject(error);
        });
    });
  };
}

export function getUserPerformanceForWeek(
  username,
  startDate,
  endDate,
  isLastWeek = false,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/weeklyGoals/getUserPerformance', {
          params: { username, startDate, endDate },
        })
        .then((response) => {
          const data = response.data;
          const userPerformancePersonal = data.userPerformancePersonal || {};
          const userPerformanceForLevel = data.userPerformanceForLevel || {};
          const userPerformanceMidWeek = data.userPerformanceMidWeek || {};
          const analyzedMediaLogs = data.analyzedMediaLogs || [];
          const dailyActivity = data.dailyActivity || {};

          if (isLastWeek) {
            dispatch(loadLastWeekGoals(userPerformancePersonal));
          } else {
            dispatch(loadAnalyzeMediaLogs(analyzedMediaLogs));
            dispatch(loadThisWeekGoals(userPerformancePersonal));
            dispatch(loadThisMidWeekGoals(userPerformanceMidWeek));
            dispatch(loadLevelGoals(userPerformanceForLevel));
            dispatch(loadDailyActivity(dailyActivity));
          }
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching weekly goals', error);
          toast(
            'Error occurred while fetching weekly goals. Please try again.',
            { type: 'error' },
          );
          resolve();
        });
    });
  };
}

export function getUserPerformanceForCurrentWeek(
  username,
  bypassCache = false,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/weeklyGoals/getUserPerformanceForCurrentWeek', {
          params: { username, bypassCache },
        })
        .then((response) => {
          const data = response.data;
          const userPerformancePersonal =
            data.thisWeek.userPerformancePersonal || {};
          const userPerformanceForLevel =
            data.thisWeek.userPerformanceForLevel || {};
          const userPerformanceMidWeek =
            data.thisWeek.userPerformanceMidWeek || {};
          const userPerformanceLastMidWeek =
            data.lastWeek.userPerformanceMidWeek || {};
          const userPerformanceLastWeekLevel =
            data.lastWeek.userPerformanceForLevel || {};
          const analyzedMediaLogs = data.thisWeek.analyzedMediaLogs || [];
          const lastWeekAnalyzedMediaLogs =
            data.lastWeek.analyzedMediaLogs || [];
          const dailyActivity = data.thisWeek.dailyActivity || {};
          const lastWeekDailyActivity = data.lastWeek.dailyActivity || {};
          const goalsDefinition = data.goalsDefinition || [];

          dispatch(loadLastWeekGoals(data.lastWeek.userPerformancePersonal));
          dispatch(loadAnalyzeMediaLogs(analyzedMediaLogs));
          dispatch(loadLastWeekAnalyzeMediaLogs(lastWeekAnalyzedMediaLogs));
          dispatch(loadThisWeekGoals(userPerformancePersonal));
          dispatch(loadThisMidWeekGoals(userPerformanceMidWeek));
          dispatch(loadLastMidWeekGoals(userPerformanceLastMidWeek));
          dispatch(loadLevelGoals(userPerformanceForLevel));
          dispatch(loadLastWeekLevelGoals(userPerformanceLastWeekLevel));
          dispatch(loadDailyActivity(dailyActivity));
          dispatch(loadLastWeekDailyActivity(lastWeekDailyActivity));
          dispatch(loadGoalsDefinition(goalsDefinition));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching weekly goals', error);
          toast(
            'Error occurred while fetching weekly goals. Please try again.',
            { type: 'error' },
          );
          resolve();
        });
    });
  };
}

export function getLevelGoals(username) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/weeklyGoals/getLevelGoals', {
          params: { username },
        })
        .then((response) => {
          const data = response.data;

          dispatch(loadLevelGoalsHelper(data));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching level goals', error);
          toast(
            'Error occurred while fetching level goals. Please try again.',
            { type: 'error' },
          );
          resolve();
        });
    });
  };
}

export function getGoalsDefinition() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/weeklyGoals/getGoalDefinitions')
        .then((response) => {
          const data = response.data;

          dispatch(loadGoalsDefinition(data));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching goals definition', error);
          toast(
            'Error occurred while fetching goals definition. Please try again.',
            { type: 'error' },
          );
          return resolve();
        });
    });
  };
}

export function getUserBadges() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/weeklyGoals/getUserBadges')
        .then((response) => {
          const data = response.data;

          dispatch(loadEarnedBadges(data.earnedBadges || []));
          dispatch(loadNotEarnedBadges(data.notEarnedBadges || []));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching badges', error);
          toast('Error occurred while fetching badges. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}
