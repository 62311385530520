import React, { Component } from 'react';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Helmet from 'react-helmet';

import { injectGlobal, ThemeProvider } from 'styled-components';
import Footer from 'components/layout/Footer/Footer';
import AdminRoutes from './AdminRoutes';

import { ToastContainer } from 'components/elements/molecules/Toast/Toast';
import ScrollButton from 'components/elements/molecules/ScrollButton/ScrollButton';
import Hint from 'components/elements/molecules/Hint/Hint';
import PopoverCard from 'components/elements/molecules/PopoverCard/PopoverCard';
import ModalContainer from 'components/modals/ModalContainer';
import CloudinaryWidget from 'components/elements/molecules/Images/CloudinaryWidget';
import BottomSheet from 'components/elements/organisms/BottomSheet/BottomSheet';

import * as Actions from './redux/actions/index';
import { getAppVariables } from './redux/actions/app';
import { AWSCognitoInstance } from './services/AWSCognito';

// const withDragDropContext = createDndContext(HTML5Backend);

const theme = {
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
};

/* eslint no-unused-expressions: 0 */

injectGlobal`
  html {
    font-size: 62.5%;
  }
  html,
  body {
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    overscroll-behavior: none;
  }

  body {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  }
  h2{
     font-family:'Open Sans';
  }
  p,
  label ,div, span {
    font-family: 'Open Sans', Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }
`;

const REACT_APP_SCROLLING_CONTAINER_ID =
  process.env.REACT_APP_SCROLLING_CONTAINER_ID;

class AdminApp extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { user } = this.props;

    const parsed = qs.parse(this.props.location.search.substr(1));
    this.setState({
      noHeader: parsed.noHeader,
    });

    this.props.dispatch(getAppVariables());
  }

  componentDidUpdate() {
    const { location, user, app } = this.props;
    const parsed = qs.parse(location.search.substr(1));

    console.log('location.pathname', location.pathname);

    if (
      !location.pathname.startsWith('/admin-courses') &&
      !location.pathname.startsWith('/writer') &&
      !location.pathname.startsWith('/researchPrompt') &&
      !location.pathname.startsWith('/reading')
    ) {
      this.props.setImpersonateUser(null, null);
    }

    // Force logout if requested username does not match with login username
    // Required for CE to sync user between CE and IFrame
    if (
      parsed.username &&
      user &&
      user.username &&
      user.username !== parsed.username
    ) {
      AWSCognitoInstance.logout(user.username);
      this.props.actions.manualLogout(location.pathname);
      return;
    }

    if (
      typeof app.topics === 'object' &&
      Object.keys(app.topics).length === 0 &&
      user.username &&
      user.profile &&
      user.profile.roles
    ) {
      let isUserCurator =
        user.profile.roles.findIndex(
          (i) => i.type === 'curator' && i.status === 'activated',
        ) !== -1;

      if (isUserCurator) {
        this.props.dispatch(getAppVariables());
      }
    }
  }

  renderFooter() {
    const { user } = this.props;

    if (user._id) {
      return null;
    }

    return <Footer />;
  }

  render() {
    let isHeader = true;

    // wait for session to initialize before rendering main routes.
    return (
      <ThemeProvider theme={theme}>
        <div id={REACT_APP_SCROLLING_CONTAINER_ID}>
          <Helmet titleTemplate="%s - Averpoint" defaultTitle="Averpoint" />
          {this.props.initializing ? (
            <div>Initializing AverPoint...</div>
          ) : (
            <React.Fragment>
              <AdminRoutes />
              <BottomSheet />
              <ModalContainer />
              <ToastContainer />
              <ScrollButton />
              <CloudinaryWidget />
              <Hint />
              <PopoverCard />
            </React.Fragment>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  initializing: state.user.initializing,
  user: state.user ? state.user.user : null,
  app: state.app ? state.app : {},
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    setImpersonateUser: (username, userId) => {
      dispatch(Actions.setImpersonateUser(username, userId));
    },
    dispatch,
  };
}

AdminApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminApp));

export default AdminApp;
