import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';

import { getLogo, logoProvider } from 'helpers/sourceIcon';
import SourceDefault from 'helpers/source-default.svg';
import { Icon, ICONS } from 'components/Icons';
import ToolTip from 'components/elements/atoms/ToolTip/ToolTip';

import './SourceLogo.scss';

const getSourceLogo = (url) => {
  try {
    const sourceURL = new URL(url);
    return getLogo({ domain: sourceURL.host });
  } catch (error) {
    console.log('Error while parsing url', url, error);
    return SourceDefault;
  }
};

const getTooltipMessage = (sourceStatus, source, showLimitedToCourse) => {
  switch (sourceStatus) {
    case 'approved':
      return {
        message: `${source || 'This source'
          } is an approved source, and this article is compatible with AverPoint\'s browser extension.`,
        color: 'green',
      };
    case 'limitedToCourse':
      return {
        message: `${source || 'This source'
          } is not an approved source in AverPoint, but the browser extension will log reading and allow the Credibility Layer for students in this class.`,
        color: 'yellow',
      };
    default:
      return {
        message: `${source || 'This source'
          } is not an approved source in AverPoint`,
        color: 'red',
      };
  }
};

export default function SourceLogo({
  url,
  setSourceName,
  isLoading,
  showLimitedToCourse,
  displaySourceName,
  showAverPointReaderLogo = false,
}) {
  const [loading, setLoading] = useState(false);
  const [sourceStatus, setSourceStatus] = useState(null);
  const [source, setSource] = useState('');

  useEffect(() => {
    if (
      !url ||
      url.includes('averpoint.com') ||
      url.includes('localhost:3000')
    ) {
      setSourceStatus('approved');
      setSource('AverPoint Reader');

      return;
    }
    setLoading(true);

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SOURCE_PROVIDER_LAMBDA}/default?searchterm=${url}&type=sourceURL`,
    })
      .then((response) => {
        const data = response.data;
        if (setSourceName) {
          setSourceName(data.name);
        }
        setSource(data.name);
        setSourceStatus(data.sourceApprovalStatus);
      })
      .catch((error) => {
        console.error('Error while checking source approval status', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isLoading]);

  const { message, color } = getTooltipMessage(
    sourceStatus,
    source,
    showLimitedToCourse,
  );

  return (
    <span className="reading-source-logo">
      <div className="image-container">
        <ToolTip toolTipText={loading ? null : message} toolTipPosition="right">
          <img src={getSourceLogo(url)} />
          {displaySourceName && source && (
            <span className="ml-2 source-name">{source}</span>
          )}
          {!loading && (
            <div className={`status-check ${color}`}>
              <Icon icon={ICONS.CHECK} size={10} />
            </div>
          )}
          {showAverPointReaderLogo && (
            <img src={`${logoProvider}averpoint.com`} className="averpoint-logo" />
          )}
        </ToolTip>
      </div>
    </span>
  );
}
