import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './contact.scss';

import { Icon, ICONS } from 'components/Icons';
import FloatingTextarea from 'components/elements/atoms/FloatingTextarea/FloatingTextarea';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RoundedButton from 'components/elements/molecules/RoundedButton/RoundedButton';
import RadioGroup from 'components/elements/molecules/Radio/RadioGroup';
import RadioOption from 'components/elements/molecules/Radio/RadioOption';

import validation from 'helpers/validation';

export default class ContactComponent extends Component {
  static propTypes = {
    user: PropTypes.object,
    authenticated: PropTypes.bool,
    actions: PropTypes.object,
    showClose: PropTypes.bool,
    onClose: PropTypes.func,
    isContactPage: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      viewComponent: 'contact', // "contact" or "success",
      errorMessages: [],
      loggedOutName: '',
      message: '',
      contactMethod: 'email',
      email: this.props.authenticated ? this.props.user.email : '',
      phone: '',
    };
  }

  render() {
    return (
      <div
        className={`contact-component ${
          this.props.isContactPage ? 'justify-content-start mt-0' : ''
        }`}
      >
        {this.renderCloseButton()}
        {this.renderContactPage()}
        {this.renderSuccessPage()}
      </div>
    );
  }

  renderContactPage = () => {
    if (this.state.viewComponent != 'contact') return null;

    return (
      <div className="submit-component">
        {this.renderCloseButton()}
        {this.renderHeading()}
        {this.renderContactForm()}
        {this.renderSubmitButton()}
      </div>
    );
  };

  renderCloseButton = () => {
    if (!this.props.showClose) return null;

    return <Icon icon={ICONS.ARROW_DOWN} onClick={this.props.onClose} />;
  };

  renderHeading = () => (
    <div className="heading">
      {!this.props.isContactPage && <div className="title">Contact Us</div>}
      <div className="description">
        We'd love to hear from you! Send us some feedback, request a feature, or
        just say hi.
      </div>
    </div>
  );

  renderContactForm = () => {
    const name = this.props.authenticated
      ? this.props.user.profile.firstName
      : this.state.loggedOutName;

    return (
      <div className="contact-form">
        <div className="message">
          <FloatingTextarea
            value={this.state.message}
            onChange={e => this.onMessageChanged(e.target.value)}
            labelName="Your message"
            disabled={false}
            error={this.state.errorMessages.message}
            minRows={1}
            autoFocus
          />
        </div>
        <div className="name">
          <FloatingTextarea
            value={name}
            onChange={e => this.setState({ loggedOutName: e.target.value })}
            labelName="Your name"
            disabled={this.props.authenticated}
            error={this.state.errorMessages.name}
            minRows={1}
          />
        </div>
        <div className="contact-email">
          <FloatingInput
            value={this.state.email}
            onChange={this.emailChanged}
            labelName="Email"
            disabled={false}
            error={this.state.errorMessages.email}
            required
          />
        </div>
        <div className="contact-phone">
          <FloatingInput
            value={this.state.phone}
            onChange={this.phoneChanged}
            labelName="Phone"
            disabled={false}
            error={this.state.errorMessages.phone}
          />
        </div>
        {this.renderPreferredContact()}
      </div>
    );
  };

  emailChanged = e => {
    const email = e.target.value;
    // Validate
    this.setState({ email });
  };

  phoneChanged = e => {
    const phone = e.target.value;
    // Validate

    this.setState({ phone });
  };

  handleChangeContactType = event => {
    // event.preventDefault();
    event.stopPropagation();

    this.setState({
      contactMethod: event.target.value,
    });
  };

  renderPreferredContact = () => (
    <div className="contact-type">
      What's the best way to reach you?
      <RadioGroup
        name="contactTypeOption"
        label=""
        handleChange={this.handleChangeContactType}
        errorMessage=""
      >
        <div className="d-flex">
          <RadioOption
            name="contactTypeOption"
            id="email"
            value="email"
            label="Email"
            isChecked
          />
          &nbsp; &nbsp; &nbsp; &nbsp;
          <RadioOption
            name="contactTypeOption"
            id="phone"
            value="phone"
            label="Phone"
          />
        </div>
      </RadioGroup>
    </div>
  );

  renderSubmitButton = () => (
    <div className="submit-button mt-4">
      <RoundedButton
        label="Submit message"
        onClick={this.onSubmitMessage}
        disabled={false}
      />
    </div>
  );

  renderSuccessPage = () => {
    if (this.state.viewComponent !== 'success') return null;

    return (
      <div className="success-component">
        Thanks for your message! We received it and we'll get back to you soon.
        <div className="submit-button">
          <RoundedButton
            label="Sounds good"
            onClick={() => {
              this.setState({ viewComponent: 'contact' });
              if (this.props.onClose) this.props.onClose();
            }}
            disabled={false}
          />
        </div>
      </div>
    );
  };

  onMessageChanged = message => {
    // validate

    this.setState({ message });
  };

  onSubmitMessage = () => {
    // validate
    const { message, contactMethod, email, phone, loggedOutName } = this.state;

    if (!this.validate()) return;

    this.props.actions
      .submitCustomerContact({
        message,
        contactMethod,
        email,
        phone,
        loggedOutName,
      })
      .then(result => {
        this.setState({ viewComponent: 'success' });
      });
  };

  validate = () => {
    const { errorMessages } = this.state;
    let isValid = true;

    if (!this.props.authenticated && !this.state.loggedOutName) {
      isValid = false;
      errorMessages.name = 'Please tell us your name';
    }

    if (!validation.isValidEmail(this.state.email)) {
      errorMessages.email = 'Please enter a valid email';
      isValid = false;
    } else {
      errorMessages.email = '';
    }

    if (this.state.phone && !validation.isValidPhone(this.state.phone)) {
      errorMessages.phone = 'Please enter a valid phone';
      isValid = false;
    } else {
      errorMessages.phone = '';
    }

    this.setState({ errorMessages }, () => {
      console.log('Error Messages set');
    });

    return isValid;
  };
}
